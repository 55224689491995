define('m14a/controllers/impressum', ['exports', 'ember'], function (exports, Ember) {

  'use strict';


  exports['default'] = Ember['default'].Controller.extend({

    countryCode: "DE",

    init: function init() {

      this._super();
    },

    germany: (function () {
      var self = this;

      if (self.get("countryCode") === "DE") {
        return true;
      } else {
        return false;
      }
    }).property('countryCode'),

    swiss: (function () {
      var self = this;

      if (self.get("countryCode") === "CH") {
        return true;
      } else {
        return false;
      }
    }).property('countryCode'),

    austria: (function () {
      var self = this;

      if (self.get("countryCode") === "AT") {
        return true;
      } else {
        return false;
      }
    }).property('countryCode'),

    actions: {
      contact: function contact() {
        var part1 = "info";
        var part2 = Math.pow(2, 6);
        var part3 = String.fromCharCode(part2);
        var part4 = "swg-produktion.de";

        window.location.href = "mai" + "lto" + ":" + part1 + part3 + part4;
      }

    }

  });

});